<template>
  <b-card class="p-1">
    <div class="d-flex justify-content-between align-items-center mb-1">
      <b-form-group class="w-50 mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Wyszukaj po nazwie" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''"> Wyczyść </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-button
        :to="{ name: 'downloadable-files-tags-create' }"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="btn">
        Dodaj
      </b-button>
    </div>
    <b-table
      responsive
      bordered
      striped
      hover
      :items="tags"
      :fields="fields"
      :filter="filter"
      :filter-included-fields="filterOn">
      <template #cell(actions)="row">
        <div class="d-flex flex-column align-items-center">
          <b-button
            :to="{ name: 'downloadable-files-tags-edit', params: { id: row.item.id } }"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="btn table-btn"
            size="sm">
            Edytuj
          </b-button>
          <b-button class="mt-1 table-btn" variant="danger" size="sm" @click="destroy(row.item)"> Usuń </b-button>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import NotificationMixin from '@/mixins/NotificationMixin';

import { all, destroy } from '@/http/downloadable-files-tags';

export default {
  mixins: [NotificationMixin],

  data() {
    return {
      tags: [],
      filter: '',
      filterOn: [],
      fields: [
        { key: 'name', label: 'Tytuł', sortable: true },
        { key: 'actions', label: 'Akcje', class: 'w-25' },
      ],
    };
  },

  async mounted() {
    this.fetchTags();
  },

  methods: {
    async fetchTags() {
      try {
        const { data } = await all({ lang: 'pl' });

        this.tags = data.data;
      } catch (error) {
        this.showErrorNotification(
          'Problem z pobraniem danych',
          'Wystąpił błąd podczas pobierania tagów. Skontaktuj się ze swoim developerem.',
        );
      }
    },
    destroy(tag) {
      this.$swal({
        title: 'Jesteś pewny?',
        text: 'Czy chcesz usunąć ten tag?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Usuń',
        cancelButtonText: 'Anuluj',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          destroy(tag.id)
            .then(() => {
              this.showSuccessNotification('Usunięto tag', 'Tag został usunięty.');
              this.fetchTags();
            })
            .catch(() => {
              this.showErrorNotification(
                'Problem z usunięciem tagu',
                'Wystąpił błąd podczas usuwania tagu. Skontaktuj się ze swoim developerem.',
              );
            });
        }
      });
    },
  },
};
</script>

<style lang="scss"></style>
